import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";

import "./style.scss";

function hideModules() {
  document.querySelectorAll(".module").forEach((module) => {
    module.style.display = "none";
  });
}

function showModule(module) {
  hideModules();
  document.querySelector(`.module${module}`).style.display = "block";
}

export default function Manualbmb212() {
  document.title = "Manual BMB212 | LsykApp";
  useEffect(() => {
    hideModules();
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Manual BMB 212</h1>
          </div>
          <div className="col-12">
            <h2>Modulos:</h2>
            <div className="moduleBtns text-center">
              <button
                className="btn btn-primary me-4 mb-4"
                id="moduleB"
                onClick={() => showModule("b")}
              >
                Modulo B
              </button>
              <button
                className="btn btn-primary me-4 mb-4"
                id="moduleS"
                onClick={() => showModule("s")}
              >
                Modulo S
              </button>
              <button
                className="btn btn-primary me-4 mb-4"
                id="moduleK"
                onClick={() => showModule("k")}
              >
                Modulo K
              </button>
              <button
                className="btn btn-primary me-4 mb-4"
                id="moduleC"
                onClick={() => showModule("c")}
              >
                Modulo C
              </button>
              <button
                className="btn btn-primary me-4 mb-4"
                id="moduleM"
                onClick={() => showModule("m")}
              >
                Modulo M
              </button>
            </div>
            <div className="col-12 modulos">
              <div className="row">
                <ModuloB />
                <ModuloS />
                <ModuloK />
                <ModuloC />
                <ModuloM />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ModuloB() {
  const [bcolor, setBcolor] = useState("");
  const [energia, setEnergia] = useState("");
  const [texto, setTexto] = useState("");
  const [solucion, setSolucion] = useState("Esperando datos...");

  const handleBcolor = (color) => {
    setBcolor(color);
  };

  const handleEnergia = (energia) => {
    setEnergia(energia);
  };

  const handleTexto = (texto) => {
    setTexto(texto);
  };

  useEffect(() => {
    if (texto === "manten" && bcolor === "rojo") {
      if (energia !== "") {
        if (energia === "rojo") {
          setSolucion("Presionar y soltar cuando el contador contenga un 6.");
        } else {
          setSolucion("Presionar y soltar cuando el contador contenga un 2.");
        }
      }
    } else if (texto === "aborta" && bcolor === "rojo") {
      setSolucion("Presionar y soltar cuando el contador contenga un 5.");
    } else if (texto === "toca" && bcolor === "rojo") {
      if (energia !== "") {
        if (energia === "verde") {
          setSolucion("Presionar y soltar cuando el contador contenga un 2.");
        } else {
          setSolucion("Presionar y soltar INMEDIATAMENTE.");
        }
      }
    } else if (texto === "toca" && bcolor === "blanco") {
      setSolucion("Presionar y soltar cuando el contador contenga un 5.");
    } else if (texto === "aborta" && bcolor === "blanco") {
      if (energia !== "") {
        if (energia === "azul") {
          setSolucion("Presionar y soltar cuando el contador contenga un 5.");
        } else {
          setSolucion("Presionar y soltar INMEDIATAMENTE.");
        }
      }
    } else if (texto === "manten" && bcolor === "blanco") {
      if (energia !== "") {
        if (energia === "azul") {
          setSolucion("Presionar y soltar cuando el contador contenga un 2.");
        } else {
          setSolucion("Presionar y soltar INMEDIATAMENTE.");
        }
      }
    } else if (texto === "toca" && bcolor === "amarillo") {
      setSolucion("Presionar y soltar cuando el contador contenga un 2.");
    } else if (texto === "aborta" && bcolor === "amarillo") {
      if (energia !== "") {
        if (energia === "azul") {
          setSolucion("Presionar y soltar INMEDIATAMENTE.");
        } else {
          setSolucion("Presionar y soltar cuando el contador contenga un 5.");
        }
      }
    } else if (texto === "manten" && bcolor === "amarillo") {
      setSolucion("Presionar y soltar cuando el contador contenga un 6.");
    } else {
      setSolucion("Presione y suelte INMEDIATAMENTE.");
    }
  }, [bcolor, energia, texto]);

  return (
    <>
      <div className="col-12 module moduleb">
        <h4>ModuloB</h4>
        <div className="row">
          <ul>
            <li className="mb-4">
              Texto del boton ({texto}):
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <button
                      className="me-4 mb-4"
                      onClick={() => handleTexto("manten")}
                    >
                      Mantén
                    </button>
                    <button
                      className="me-4 mb-4"
                      onClick={() => handleTexto("aborta")}
                    >
                      Aborta
                    </button>
                    <button
                      className="me-4 mb-4"
                      onClick={() => handleTexto("toca")}
                    >
                      Toca
                    </button>
                    <button
                      className="me-4 mb-4"
                      onClick={() => handleTexto("otro")}
                    >
                      Otro
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li className="mb-4">
              Color del botón ({bcolor}):
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <button
                      className="me-4 mb-4 btn btn-danger"
                      onClick={() => handleBcolor("rojo")}
                    >
                      Rojo
                    </button>
                    <button
                      className="me-4 mb-4 btn btn-light"
                      onClick={() => handleBcolor("blanco")}
                    >
                      Blanco
                    </button>
                    <button
                      className="me-4 mb-4 btn btn-warning"
                      onClick={() => handleBcolor("amarillo")}
                    >
                      Amarillo
                    </button>
                    <button
                      className="me-4 mb-4 btn btn-dark"
                      onClick={() => handleBcolor("otro")}
                    >
                      Otro
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li className="mb-4">
              Energía ({energia}):
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    {/*roja, verde, azul, */}
                    <button
                      className="me-4 mb-4 btn btn-danger"
                      onClick={() => handleEnergia("rojo")}
                    >
                      Rojo
                    </button>
                    <button
                      className="me-4 mb-4 btn btn-success"
                      onClick={() => handleEnergia("verde")}
                    >
                      Verde
                    </button>
                    <button
                      className="me-4 mb-4 btn btn-primary"
                      onClick={() => handleEnergia("azul")}
                    >
                      Azul
                    </button>
                    <button
                      className="me-4 mb-4 btn btn-dark"
                      onClick={() => handleEnergia("otro")}
                    >
                      Otro
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li className="mb-4">
              Solucion:
              <div className="col-12">
                <div className="row">
                  <div className="col-12">{solucion}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

function ModuloS() {
  return (
    <>
      <div className="col-12 module modules">
        <h4>ModuloS</h4>
      </div>
    </>
  );
}

function ModuloK() {
  // eslint-disable-next-line
  const series = [
    "ATCREHG",
    "DARTBYO",
    "LÑRUDAR",
    "ESAOILF",
    "NAIHGYR",
    "FLOAXPE",
  ];

  const [letras, setLetras] = useState("");
  const [solucion, setSolucion] = useState("Esperando datos...");

  const handleLetras = (letras) => {
    setLetras(letras);
  };

  useEffect(() => {
    const uLetras = letras.toUpperCase();

    const serieEncontrada = series.find((serie) => {
      for (let letra of uLetras) {
        if (!serie.includes(letra)) {
          return false;
        }
      }
      return true;
    });

    if (serieEncontrada) {
      let solucionOrdenada = "";
      for (let letra of serieEncontrada) {
        if (uLetras.includes(letra)) {
          solucionOrdenada += letra;
        }
      }
      setSolucion(`Solución: ${solucionOrdenada}`);
    } else {
      setSolucion("¿Son las letras correctas?");
    }
  }, [letras, series]);

  return (
    <>
      <div className="col-12 module modulek">
        <h4>ModuloK</h4>
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <input
                type="text"
                onChange={(e) => handleLetras(e.target.value)}
              />
            </div>
            <div className="col-12">{solucion}</div>
          </div>
        </div>
      </div>
    </>
  );
}

function ModuloC() {
  return (
    <>
      <div className="col-12 module modulec">
        <h4>ModuloC</h4>
      </div>
    </>
  );
}

function ModuloM() {
  return (
    <>
      <div className="col-12 module modulem">
        <h4>ModuloM</h4>
      </div>
    </>
  );
}
