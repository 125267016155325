import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "./index.scss";

export default function Home() {
  return (
    <>
      <div className="container-fluid homepage">
        <div className="row">
          <div className="background"></div>
          <div className="logo"></div>
          <h1 className="text-center title">Lsyk Apps</h1>
        </div>
      </div>
    </>
  );
}

export function NotFound() {
  return(
    <>
      <div className="container-fluid notfound">
        <div className="row">
          <div className="background"></div>
          <h1 className="text-center title">404</h1>
        </div>
      </div>
    </>
  )
}

export function AppsDirectory (){
  return (
      <>
          <div className="container appsdirectory mt-4">
              <h1 className="title"><span>A</span>pps Directory</h1>
              <ul className="applist">
                <li>
                  <a href="/manualbmb212">Manual BMB 212</a>
                </li>
              </ul>
          </div>
      </>
  )
}
