import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home, { AppsDirectory, NotFound } from "./pages";

// Pages and apps
import Manualbmb212 from "./pages/manualbmb212";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/appsdirectory" element={<AppsDirectory />} />
          <Route path="/manualbmb212" element={<Manualbmb212 />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
